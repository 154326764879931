<template>
  <div class="about">
  
  </div>
</template>
<script>

export default {
  name: 'About',
  components: {
    
  }
}
</script>